"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Option = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_select_1 = require("react-select");
const theme_ui_1 = require("theme-ui");
// https://github.com/JedWatson/react-select/issues/685#issuecomment-420213835
const Option = (props) => {
    const option = props.data;
    if (option.imageElement) {
        return ((0, jsx_runtime_1.jsx)(react_select_1.components.Option, { ...props, children: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(theme_ui_1.Flex, { sx: { alignItems: 'center', justifyContent: 'space-between' }, mt: "5px", children: (0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { sx: { alignItems: 'center' }, children: [option.imageElement, (0, jsx_runtime_1.jsxs)(theme_ui_1.Text, { sx: { fontSize: 2 }, ml: "10px", children: [option.label, option.number && ` (${option.number})`] })] }) }, option.label) }) }));
    }
    return (0, jsx_runtime_1.jsx)(react_select_1.components.Option, { ...props, children: props.label });
};
exports.Option = Option;
