"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextNotification = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@emotion/react");
const theme_ui_1 = require("theme-ui");
const fadeIn = (0, react_1.keyframes)({
    from: { opacity: 0, transform: 'translateY(-50%)' },
    to: { opacity: 1 },
});
const TextNotification = (props) => {
    if (!props.isVisible) {
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
    }
    return ((0, jsx_runtime_1.jsxs)(theme_ui_1.Alert, { variant: props.variant, "data-cy": `TextNotification: ${props.variant}`, sx: {
            width: '100%',
            animation: `${fadeIn} ease-out 400ms both 200ms`,
        }, children: [props.children, props.onDismiss && ((0, jsx_runtime_1.jsx)(theme_ui_1.Close, { sx: {
                    position: 'absolute',
                    top: '50%',
                    right: 2,
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                }, onClick: () => props.onDismiss(false) }))] }));
};
exports.TextNotification = TextNotification;
