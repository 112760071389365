"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OsmGeocodingLoader = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_ui_1 = require("theme-ui");
const OsmGeocodingLoader = () => {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(theme_ui_1.Box, { sx: {
                background: 'white',
                position: 'relative',
                zIndex: 1,
                marginTop: '-2px',
                paddingX: 2,
                paddingY: 1,
                border: '2px solid',
                borderColor: 'black',
                borderTopWidth: '1px',
                lineHeight: 1.5,
                borderBottomLeftRadius: 1,
                borderBottomRightRadius: 1,
            }, children: (0, jsx_runtime_1.jsx)(theme_ui_1.Text, { sx: { fontSize: 1 }, children: "Fetching results from Open Street Map" }) }) }));
};
exports.OsmGeocodingLoader = OsmGeocodingLoader;
