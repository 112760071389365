"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Icon_1 = require("../Icon/Icon");
const theme_ui_1 = require("theme-ui");
const buttonSizeProps = {
    small: {
        px: 2,
        py: 1,
        pl: 7,
        fontSize: 1,
        height: '2rem',
    },
    default: {
        px: 3,
        pl: 9,
    },
    large: {
        px: 4,
        py: 3,
        pl: 10,
        fontSize: 4,
        height: '3.5rem',
    },
};
function getSizeProps(size, hasIcon) {
    if (!buttonSizeProps[`${size}`] && !hasIcon) {
        return {};
    }
    if (!buttonSizeProps[`${size}`] && hasIcon) {
        return {
            px: 3,
            pl: 9,
        };
    }
    const sizeProps = { ...buttonSizeProps[`${size}`] };
    if (!hasIcon) {
        delete sizeProps.pl;
    }
    return sizeProps;
}
function getScaleTransform(size) {
    if (size === 'large') {
        return 1.25;
    }
    return 1;
}
function sanitizedProps(obj, keysToRemove) {
    const sanitizedObj = { ...obj };
    keysToRemove.forEach((prop) => {
        if (sanitizedObj[prop]) {
            delete sanitizedObj[prop];
        }
    });
    return sanitizedObj;
}
const Button = (props) => {
    const [size] = Object.keys(props).filter((prop) => Object.prototype.hasOwnProperty.call(buttonSizeProps, prop));
    return ((0, jsx_runtime_1.jsxs)(theme_ui_1.Button, { ...sanitizedProps(props, [
            'small',
            'large',
            'showIconOnly',
            'iconColor',
        ]), sx: {
            ...props.sx,
            ...getSizeProps(size, !!props.icon),
            ...(props.showIconOnly ? { pr: 0 } : {}),
        }, children: [props.icon && ((0, jsx_runtime_1.jsx)(theme_ui_1.Flex, { "aria-hidden": true, sx: {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    px: getSizeProps(size, !!props.icon)?.px || 0,
                    boxSizing: 'border-box',
                    fontSize: 0,
                    maxWidth: '100%',
                    lineHeight: 0,
                    transform: `translateY(-1px) scale(${getScaleTransform(size)})`,
                    pointerEvents: 'none',
                }, children: (0, jsx_runtime_1.jsx)(Icon_1.Icon, { glyph: props.icon, color: props.iconColor }) })), (0, jsx_runtime_1.jsx)(theme_ui_1.Text, { sx: {
                    ...(props.showIconOnly
                        ? {
                            clipPath: 'inset(100%)',
                            clip: 'rect(1px, 1px, 1px, 1px)',
                            height: '1px',
                            overflow: 'hidden',
                            position: 'absolute',
                            whiteSpace: 'nowrap',
                            width: '1px',
                        }
                        : {}),
                }, children: props.children })] }));
};
exports.Button = Button;
exports.Button.defaultProps = {
    type: 'button',
};
