"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResearchEditorOverview = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_ui_1 = require("theme-ui");
const InternalLink_1 = require("../InternalLink/InternalLink");
const Button_1 = require("../Button/Button");
const yup_1 = require("yup");
const updateSchema = (0, yup_1.object)({
    isActive: (0, yup_1.boolean)().required(),
    title: (0, yup_1.string)().required(),
    status: (0, yup_1.string)().optional(),
    slug: (0, yup_1.string)().nullable(),
});
const ResearchEditorOverview = (props) => {
    const { updates, sx, researchSlug, showCreateUpdateButton, showBackToResearchButton, } = props;
    return ((0, jsx_runtime_1.jsxs)(theme_ui_1.Card, { sx: { p: 4, ...sx }, children: [(0, jsx_runtime_1.jsx)(theme_ui_1.Heading, { as: "h3", mb: 3, variant: "small", children: "Research overview" }), updates?.length ? ((0, jsx_runtime_1.jsx)(theme_ui_1.Box, { as: 'ul', sx: { margin: 0, mb: 4, p: 0, pl: 3 }, children: updates
                    .filter((update) => updateSchema.isValidSync(update))
                    .map((update, index) => ((0, jsx_runtime_1.jsx)(theme_ui_1.Box, { as: 'li', sx: { mb: 1 }, children: (0, jsx_runtime_1.jsxs)(theme_ui_1.Text, { variant: 'quiet', children: [update.status === 'draft' ? ((0, jsx_runtime_1.jsx)(theme_ui_1.Text, { sx: {
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    color: 'black',
                                    fontSize: 1,
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    background: 'accent.base',
                                    padding: 1,
                                    borderRadius: 1,
                                    borderBottomRightRadius: 1,
                                    mr: 1,
                                }, children: "Draft" })) : null, update.isActive ? ((0, jsx_runtime_1.jsx)("strong", { children: update.title })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [update.title, update.slug ? ((0, jsx_runtime_1.jsx)(InternalLink_1.InternalLink, { to: `/research/${researchSlug}/edit-update/${update.slug}`, sx: { display: 'inline-block', ml: 1 }, children: "Edit" })) : null] }))] }) }, index))) })) : null, showCreateUpdateButton ? ((0, jsx_runtime_1.jsx)(Button_1.Button, { small: true, sx: { mr: 2 }, children: (0, jsx_runtime_1.jsx)(InternalLink_1.InternalLink, { to: `/research/${researchSlug}/new-update`, sx: { color: 'black' }, children: "Create update" }) })) : null, showBackToResearchButton ? ((0, jsx_runtime_1.jsx)(Button_1.Button, { small: true, variant: 'outline', children: (0, jsx_runtime_1.jsx)(InternalLink_1.InternalLink, { to: `/research/${researchSlug}/edit`, sx: { color: 'black' }, children: "Back to research" }) })) : null] }));
};
exports.ResearchEditorOverview = ResearchEditorOverview;
