"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateComment = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_ui_1 = require("theme-ui");
const react_router_dom_1 = require("react-router-dom");
const MemberBadge_1 = require("../MemberBadge/MemberBadge");
const CreateComment = (props) => {
    const { comment, maxLength, isLoggedIn } = props;
    const userProfileType = props.userProfileType || 'member';
    const { onSubmit } = props;
    const onChange = (newValue) => {
        props.onChange && props?.onChange(newValue);
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { sx: { marginBottom: 5 }, "data-target": "create-comment-container", children: [(0, jsx_runtime_1.jsx)(theme_ui_1.Box, { sx: { lineHeight: 0, marginTop: 2 }, children: (0, jsx_runtime_1.jsx)(MemberBadge_1.MemberBadge, { profileType: userProfileType, useLowDetailVersion: true }) }), (0, jsx_runtime_1.jsx)(theme_ui_1.Box, { sx: {
                            display: 'block',
                            background: 'white',
                            flexGrow: 1,
                            marginLeft: 5,
                            borderRadius: 1,
                            position: 'relative',
                            '&:before': {
                                content: '""',
                                position: 'absolute',
                                borderWidth: '1em 1em',
                                borderStyle: 'solid',
                                borderColor: 'transparent white transparent transparent',
                                margin: '1em -2em',
                            },
                        }, children: isLoggedIn ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(theme_ui_1.Textarea, { value: comment, maxLength: maxLength, onChange: (event) => {
                                        onChange && onChange(event.target.value);
                                    }, "data-cy": "comments-form", placeholder: "Leave your questions or feedback...", sx: {
                                        background: 'none',
                                        resize: 'vertical',
                                        padding: 3,
                                        '&:focus': {
                                            borderColor: 'transparent',
                                        },
                                    } }), (0, jsx_runtime_1.jsxs)(theme_ui_1.Text, { sx: {
                                        fontSize: 2,
                                        position: 'absolute',
                                        right: 0,
                                        bottom: -5,
                                        pointerEvents: 'none',
                                        padding: 1,
                                    }, children: [comment.length, "/", maxLength] })] })) : ((0, jsx_runtime_1.jsx)(theme_ui_1.Box, { sx: { padding: [3, 4] }, children: (0, jsx_runtime_1.jsxs)(theme_ui_1.Text, { "data-cy": "comments-login-prompt", children: ["Hi there!", ' ', (0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: "/sign-in", style: {
                                            textDecoration: 'underline',
                                            color: 'inherit',
                                        }, children: "Login" }), ' ', "to leave a comment"] }) })) })] }), (0, jsx_runtime_1.jsx)(theme_ui_1.Button, { "data-cy": "comment-submit", disabled: !comment.trim() || !isLoggedIn, variant: "primary", onClick: () => onSubmit(comment), mt: 3, sx: {
                    float: 'right',
                }, children: "Leave a comment" })] }));
};
exports.CreateComment = CreateComment;
