"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mappins = void 0;
exports.mappins = {
    settings_community_new: {
        _id: 'settings_community_new',
        _deleted: false,
        type: 'community-builder',
        moderation: 'awaiting-moderation',
        location: {
            lat: 51.5073,
            lng: -0.127647,
        },
        _modified: '2020-01-07T12:15:09.178Z',
        _created: '2020-01-07T12:15:09.178Z',
    },
    settings_machine_new: {
        _deleted: false,
        type: 'machine-builder',
        moderation: 'awaiting-moderation',
        location: {
            lat: 1.29048,
            lng: 103.852,
        },
        _modified: '2020-01-07T12:14:50.764Z',
        _created: '2020-01-07T12:14:50.764Z',
        _id: 'settings_machine_new',
    },
    settings_plastic_new: {
        type: 'collection-point',
        moderation: 'awaiting-moderation',
        location: {
            lat: 2.19082,
            lng: 102.256,
        },
        _modified: '2020-01-07T12:15:42.751Z',
        _created: '2020-01-07T12:15:42.751Z',
        _id: 'settings_plastic_new',
        _deleted: false,
    },
    settings_workplace_new: {
        type: 'workspace',
        moderation: 'awaiting-moderation',
        subType: 'shredder',
        location: {
            lat: 39.9623,
            lng: -83.0007,
        },
        _modified: '2020-01-07T12:14:15.541Z',
        _created: '2020-01-07T12:14:15.541Z',
        _id: 'settings_workplace_new',
        _deleted: false,
    },
    mapview_testing_rejected: {
        type: 'workspace',
        moderation: 'rejected',
        subType: 'shredder',
        location: {
            lat: 39.9623,
            lng: -83.0007,
        },
        _modified: '2020-01-07T12:14:15.541Z',
        _created: '2020-01-07T12:14:15.541Z',
        _id: 'mapview_testing_rejected',
        _deleted: false,
    },
    mapview_testing: {
        type: 'workspace',
        moderation: 'accepted',
        subType: 'shredder',
        location: {
            lat: 0,
            lng: 0,
        },
        _modified: '2020-01-07T12:14:15.541Z',
        _created: '2020-01-07T12:14:15.541Z',
        _id: 'mapview_testing_accepted_pin',
        _deleted: false,
    },
};
