"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OsmGeocoding = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const theme_ui_1 = require("theme-ui");
const OsmGeocodingResultsList_1 = require("./OsmGeocodingResultsList");
const use_debounce_1 = require("use-debounce");
const OsmGeocodingLoader_1 = require("./OsmGeocodingLoader");
const OsmGeocoding = ({ placeholder = 'Search for an address', debounceMs = 800, callback, acceptLanguage = 'en', viewbox = '', loading = false, }) => {
    const [searchValue, setSearchValue] = (0, react_1.useState)('');
    const [results, setResults] = (0, react_1.useState)([]);
    const [showResults, setShowResults] = (0, react_1.useState)(false);
    const [showLoader, setShowLoader] = (0, react_1.useState)(loading);
    const [queryLocationService, setQueryLocationService] = (0, react_1.useState)(false);
    const mainContainerRef = (0, react_1.useRef)(null);
    document.addEventListener('click', function (event) {
        const isClickInside = mainContainerRef?.current?.contains(event.target);
        if (!isClickInside) {
            setShowResults(false);
        }
    });
    document.onkeyup = function (event) {
        if (event.key === 'Escape') {
            setShowResults(false);
        }
    };
    function getGeocoding(address = '') {
        if (address.length === 0)
            return;
        setShowLoader(true);
        let url = `https://nominatim.openstreetmap.org/search?format=json&q=${address}&accept-language=${acceptLanguage}`;
        if (viewbox.length) {
            url = `${url}&viewbox=${viewbox}&bounded=1`;
        }
        fetch(url, {
            headers: new Headers({
                'User-Agent': 'onearmy.earth Community Platform (https://platform.onearmy.earth)',
            }),
        })
            .then((response) => response.json())
            .then((data) => {
            setResults(data);
            setShowResults(true);
        })
            .catch(null)
            .finally(() => setShowLoader(false));
    }
    const showResultsListing = !!results.length && showResults && !showLoader;
    const dcb = (0, use_debounce_1.useDebouncedCallback)((search) => getGeocoding(search), debounceMs);
    (0, react_1.useEffect)(() => {
        if (queryLocationService) {
            dcb(searchValue);
        }
    }, [searchValue, queryLocationService]);
    return ((0, jsx_runtime_1.jsxs)("div", { "data-cy": "osm-geocoding", ref: mainContainerRef, style: { width: '100%' }, children: [(0, jsx_runtime_1.jsx)(theme_ui_1.Input, { autoComplete: "off", type: "search", name: "geocoding", id: "geocoding", "data-cy": "osm-geocoding-input", placeholder: placeholder, value: searchValue, style: {
                    width: '100%',
                    background: 'white',
                    fontFamily: 'Varela Round',
                    fontSize: '14px',
                    border: '2px solid black',
                    height: '44px',
                    display: 'flex',
                    borderRadius: showResultsListing || showLoader ? '5px 5px 0 0' : '5px',
                    marginBottom: 0,
                }, onClick: () => setShowResults(true), onChange: (event) => {
                    setQueryLocationService(true);
                    setSearchValue(event.target.value);
                } }), showLoader && (0, jsx_runtime_1.jsx)(OsmGeocodingLoader_1.OsmGeocodingLoader, {}), showResultsListing && ((0, jsx_runtime_1.jsx)(OsmGeocodingResultsList_1.OsmGeocodingResultsList, { results: results, callback: (result) => {
                    if (result) {
                        setQueryLocationService(false);
                        setSearchValue(result.display_name);
                    }
                    callback(result);
                }, setShowResults: setShowResults }))] }));
};
exports.OsmGeocoding = OsmGeocoding;
