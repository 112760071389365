"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileLink = exports.capitalizeFirstLetter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_ui_1 = require("theme-ui");
const ExternalLink_1 = require("../ExternalLink/ExternalLink");
const Icon_1 = require("../Icon/Icon");
const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);
exports.capitalizeFirstLetter = capitalizeFirstLetter;
/**
 * Ensure urls are complete (start http/https://) and replace emails with mailto
 */
const rewriteUrl = (url, label) => {
    switch (label) {
        case 'email':
            return `mailto:${url}`;
        default:
            return url.indexOf('http') === 0 ? url : `http://${url}`;
    }
};
const socialMediaNetworks = [
    { pattern: new RegExp(/twitter\.com/), label: 'Twitter' },
    { pattern: new RegExp(/facebook\.com/), label: 'Facebook' },
    { pattern: new RegExp(/youtube\.com/), label: 'Youtube' },
    { pattern: new RegExp(/instagram\.com/), label: 'Instagram' },
];
const getLabelText = (label, url) => {
    const matchedNetwork = socialMediaNetworks.find((network) => network.pattern.test(url));
    if (matchedNetwork) {
        return matchedNetwork.label;
    }
    return label && (0, exports.capitalizeFirstLetter)(label);
};
const ProfileLink = (props) => {
    const { url, label } = props;
    return ((0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { sx: {
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
            mt: 0,
            ...props.sx,
        }, children: [(0, jsx_runtime_1.jsx)(theme_ui_1.Box, { children: (0, jsx_runtime_1.jsx)(Icon_1.Icon, { glyph: props.icon, size: 22 }) }), (0, jsx_runtime_1.jsx)(ExternalLink_1.ExternalLink, { ml: 2, color: 'black', href: rewriteUrl(url, label), children: getLabelText(label, url) })] }));
};
exports.ProfileLink = ProfileLink;
