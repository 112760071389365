"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OsmGeocodingResultsList = void 0;
const jsx_runtime_1 = require("theme-ui/jsx-runtime");
/** @jsxImportSource theme-ui */
const theme_ui_1 = require("theme-ui");
const OsmGeocodingResultsList = (props) => {
    const { results, callback, setShowResults } = props;
    return ((0, jsx_runtime_1.jsx)(theme_ui_1.Box, { "data-cy": "osm-geocoding-results", as: "ul", sx: {
            background: 'white',
            padding: 0,
            position: 'relative',
            zIndex: 1,
            margin: '-2px 0 0',
            border: `2px solid black`,
            borderTopWidth: '1px',
            listStyle: 'none',
            borderRadius: 0,
            borderBottomLeftRadius: 1,
            borderBottomRightRadius: 1,
        }, children: results.map((result, index) => ((0, jsx_runtime_1.jsx)(theme_ui_1.Box, { as: "li", sx: {
                paddingY: 1,
                paddingX: 2,
                lineHeight: 1.5,
                '&:hover': {
                    background: 'softblue',
                    cursor: 'pointer',
                },
            }, onClick: () => {
                setShowResults(false);
                if (callback) {
                    callback(result);
                }
            }, children: result?.display_name }, index))) }));
};
exports.OsmGeocodingResultsList = OsmGeocodingResultsList;
