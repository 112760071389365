"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewsCounter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_ui_1 = require("theme-ui");
const __1 = require("..");
const ViewsCounter = (props) => ((0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { "data-cy": 'ViewsCounter', px: 2, py: 1, mb: 1, sx: {
        alignItems: 'center',
        background: 'softyellow',
        borderRadius: 1,
        fontSize: 2,
        border: '1px solid',
        borderColor: 'rgba(0,0,0,0)',
    }, children: [(0, jsx_runtime_1.jsx)(__1.Icon, { glyph: 'view', mr: 2, size: 'md' }), (0, jsx_runtime_1.jsxs)(theme_ui_1.Text, { children: [props.viewsCount, props.viewsCount !== 1 ? ' views' : ' view'] })] }));
exports.ViewsCounter = ViewsCounter;
