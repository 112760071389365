"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MOCK_AUTH_USERS = void 0;
/** A list of specific demo/mock users that are prepopulated onto testing sites for use in development */
exports.MOCK_AUTH_USERS = {
    subscriber: {
        uid: 'demo_user',
        label: 'User',
        email: 'demo_user@example.com',
        password: 'demo_user',
        roles: [],
    },
    'beta-tester': {
        uid: 'demo_beta_tester',
        label: 'Beta-Tester',
        email: 'demo_beta_tester@example.com',
        password: 'demo_beta_tester',
        roles: ['beta-tester'],
    },
    admin: {
        uid: 'demo_admin',
        label: 'Admin',
        email: 'demo_admin@example.com',
        password: 'demo_admin',
        roles: ['admin'],
    },
    'super-admin': {
        uid: 'demo_super_admin',
        label: 'Super-Admin',
        email: 'demo_super_admin@example.com',
        password: 'demo_super_admin',
        roles: ['super-admin'],
    },
    research_creator: {
        uid: 'research_creator',
        label: 'Research-Creator',
        email: 'research_creator@test.com',
        password: 'research_creator',
        roles: ['beta-tester'],
    },
    research_editor: {
        uid: 'research_editor',
        label: 'Research-Editor',
        email: 'research_editor@test.com',
        password: 'research_editor',
        roles: ['beta-tester'],
    },
};
