"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModerationStatus = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_ui_1 = require("theme-ui");
const ModerationStatus = (props) => {
    const { contentType, sx } = props;
    let { status } = props;
    if (status === 'accepted') {
        // If the content has been accepted we should bail out
        // early and not render the `ModerationStatus` component.
        return null;
    }
    if (contentType === 'event') {
        status = 'draft' !== status ? status : 'awaiting-moderation';
    }
    let moderationMessage = '';
    switch (status) {
        case 'rejected':
            moderationMessage =
                'howto' === contentType ? 'Needs to improve to be accepted' : 'Rejected';
            break;
        case 'draft':
            moderationMessage = 'Draft';
            break;
        case 'awaiting-moderation':
            moderationMessage = 'Awaiting moderation';
            break;
        default:
            moderationMessage = 'Awaiting moderation';
            break;
    }
    return ((0, jsx_runtime_1.jsx)(theme_ui_1.Text, { sx: {
            ...sx,
            display: 'inline-block',
            color: status === 'rejected' ? 'red' : 'black',
            fontSize: 1,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            background: 'accent.base',
            padding: 1,
            borderRadius: 1,
            borderBottomRightRadius: 1,
        }, "data-cy": `moderationstatus-${status}`, children: moderationMessage }));
};
exports.ModerationStatus = ModerationStatus;
