"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditComment = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_ui_1 = require("theme-ui");
const __1 = require("../");
const react_final_form_1 = require("react-final-form");
const EditComment = (props) => {
    const { comment } = props;
    return ((0, jsx_runtime_1.jsx)(react_final_form_1.Form, { onSubmit: () => { }, initialValues: {
            comment,
        }, render: ({ handleSubmit, values }) => ((0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { as: "form", sx: {
                flexDirection: 'column',
            }, p: 2, onSubmit: handleSubmit, children: [(0, jsx_runtime_1.jsx)(theme_ui_1.Text, { as: "label", sx: { marginBottom: '6px', fontSize: 3 }, children: "Edit comment" }), (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { name: "comment", id: "comment", component: __1.FieldTextarea }), (0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { mt: 4, ml: "auto", children: [(0, jsx_runtime_1.jsx)(__1.Button, { small: true, mr: 4, variant: "secondary", onClick: () => props?.handleCancel(), children: "Cancel" }), (0, jsx_runtime_1.jsx)(__1.Button, { type: 'submit', small: true, onClick: () => {
                                props?.handleSubmit(values.comment);
                            }, children: "Edit" })] })] })) }));
};
exports.EditComment = EditComment;
