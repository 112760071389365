"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldDatepicker = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_ui_1 = require("theme-ui");
const FieldDatepicker = ({ input, meta, customChange, ...rest }) => {
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(theme_ui_1.Input, { type: "date", variant: meta.error && meta.touched ? 'error' : 'input', ...input, ...rest, onChange: (date) => {
                    input.onChange(date);
                    if (customChange) {
                        customChange(date);
                    }
                    input.onBlur();
                } }), meta.error && meta.touched ? ((0, jsx_runtime_1.jsx)(theme_ui_1.Text, { sx: { fontSize: 0, margin: 1, color: 'error' }, children: meta.error })) : null] }));
};
exports.FieldDatepicker = FieldDatepicker;
