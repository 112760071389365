"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExternalLink = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
// eslint-disable-next-line no-restricted-imports
const theme_ui_1 = require("theme-ui");
/**
 * Provides a styled `a` tag. Opens in new tab with noopener and noreferrer rel attributes
 *
 * https://pointjupiter.com/what-noopener-noreferrer-nofollow-explained/
 */
const ExternalLink = (props) => {
    return (0, jsx_runtime_1.jsx)(theme_ui_1.Link, { ...props, target: "_blank", rel: "noopener noreferrer" });
};
exports.ExternalLink = ExternalLink;
