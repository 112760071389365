"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FollowButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const __1 = require("..");
const react_router_dom_1 = require("react-router-dom");
const FollowButton = (props) => {
    const history = (0, react_router_dom_1.useHistory)();
    const [hasUserSubscribed, setHasUserSubscribed] = (0, react_1.useState)();
    (0, react_1.useEffect)(() => setHasUserSubscribed(props.hasUserSubscribed), [props.hasUserSubscribed]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(__1.Button, { "data-testid": props.isLoggedIn ? 'follow-button' : 'follow-redirect', "data-cy": props.isLoggedIn ? 'follow-button' : 'follow-redirect', "data-tip": props.isLoggedIn ? '' : 'Login to follow', icon: "thunderbolt", variant: "outline", iconColor: hasUserSubscribed ? 'subscribed' : 'notSubscribed', sx: {
                    fontSize: 2,
                    py: 0,
                }, onClick: () => props.isLoggedIn ? props.onFollowClick() : history.push('/sign-in'), children: hasUserSubscribed ? 'Following' : 'Follow' }), (0, jsx_runtime_1.jsx)(__1.Tooltip, {})] }));
};
exports.FollowButton = FollowButton;
