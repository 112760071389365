"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommentItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const theme_ui_1 = require("theme-ui");
const Button_1 = require("../Button/Button");
const ConfirmModal_1 = require("../ConfirmModal/ConfirmModal");
const EditComment_1 = require("../EditComment/EditComment");
const LinkifyText_1 = require("../LinkifyText/LinkifyText");
const Modal_1 = require("../Modal/Modal");
const Username_1 = require("../Username/Username");
const formatDate = (d) => {
    if (!d) {
        return '';
    }
    return new Date(d).toLocaleDateString('en-GB').replace(/\//g, '-');
};
const CommentItem = (props) => {
    const textRef = (0, react_1.createRef)();
    const [showEditModal, setShowEditModal] = (0, react_1.useState)(false);
    const [showDeleteModal, setShowDeleteModal] = (0, react_1.useState)(false);
    const [textHeight, setTextHeight] = (0, react_1.useState)(0);
    const [isShowMore, setShowMore] = (0, react_1.useState)(false);
    const { creatorCountry, creatorName, isUserVerified, _id, _edited, _created, text, handleEditRequest, handleDelete, handleEdit, isEditable, } = props;
    (0, react_1.useEffect)(() => {
        if (textRef.current) {
            setTextHeight(textRef.current.scrollHeight);
        }
    }, [text]);
    const showMore = () => {
        setShowMore(!isShowMore);
    };
    const onEditRequest = (_id) => {
        if (handleEditRequest) {
            handleEditRequest(_id);
            return setShowEditModal(true);
        }
    };
    return ((0, jsx_runtime_1.jsx)(theme_ui_1.Box, { id: `comment:${_id}`, "data-cy": "comment", children: (0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { p: "3", bg: 'white', sx: {
                width: '100%',
                flexDirection: 'column',
                borderRadius: '5px',
            }, children: [(0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { sx: { justifyContent: 'space-between', alignItems: 'baseline' }, children: [(0, jsx_runtime_1.jsx)(Username_1.Username, { user: {
                                userName: creatorName,
                                countryCode: creatorCountry,
                            }, isVerified: !!isUserVerified }), (0, jsx_runtime_1.jsx)(theme_ui_1.Flex, { sx: { alignItems: 'center' }, children: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [_edited && ((0, jsx_runtime_1.jsx)(theme_ui_1.Text, { sx: { fontSize: 0, color: 'grey' }, mr: 2, children: "(Edited)" })), (0, jsx_runtime_1.jsx)(theme_ui_1.Text, { sx: { fontSize: 1 }, children: formatDate(_edited || _created) })] }) })] }), (0, jsx_runtime_1.jsx)(theme_ui_1.Text, { "data-cy": "comment-text", mt: 2, mb: 2, sx: {
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                        overflow: 'hidden',
                        maxHeight: isShowMore ? 'max-content' : '128px',
                        fontFamily: 'body',
                        lineHeight: 1.3,
                    }, ref: textRef, children: (0, jsx_runtime_1.jsx)(LinkifyText_1.LinkifyText, { children: text }) }), textHeight > 129 && ((0, jsx_runtime_1.jsx)("a", { onClick: showMore, style: {
                        color: 'gray',
                        cursor: 'pointer',
                        fontSize: '14px',
                    }, children: isShowMore ? 'Show less' : 'Show more' })), (0, jsx_runtime_1.jsx)(theme_ui_1.Flex, { ml: "auto", children: isEditable && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(Button_1.Button, { "data-cy": "CommentItem: edit button", variant: 'outline', small: true, icon: 'edit', onClick: () => onEditRequest(_id), children: "edit" }), (0, jsx_runtime_1.jsx)(Button_1.Button, { "data-cy": "CommentItem: delete button", variant: 'outline', small: true, icon: "delete", onClick: () => setShowDeleteModal(true), ml: 2, children: "delete" })] })) }), (0, jsx_runtime_1.jsx)(Modal_1.Modal, { width: 600, isOpen: showEditModal, children: (0, jsx_runtime_1.jsx)(EditComment_1.EditComment, { comment: text, handleSubmit: (commentText) => {
                            handleEdit && handleEdit(_id, commentText);
                            setShowEditModal(false);
                        }, handleCancel: () => setShowEditModal(false) }) }), (0, jsx_runtime_1.jsx)(ConfirmModal_1.ConfirmModal, { isOpen: showDeleteModal, message: "Are you sure you want to delete this comment?", confirmButtonText: "Delete", handleCancel: () => setShowDeleteModal(false), handleConfirm: () => handleDelete && handleDelete(_id) })] }) }));
};
exports.CommentItem = CommentItem;
