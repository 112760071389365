"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArticleCallToAction = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@emotion/react");
const theme_ui_1 = require("theme-ui");
const __1 = require("../");
const ArticleCallToAction = (props) => {
    const theme = (0, react_1.useTheme)();
    return ((0, jsx_runtime_1.jsx)(theme_ui_1.Card, { sx: { py: 6, px: 4 }, children: (0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { sx: {
                flexDirection: 'column',
                alignItems: 'center',
            }, children: [(0, jsx_runtime_1.jsxs)(theme_ui_1.Text, { variant: "body", sx: { fontSize: 2 }, children: ["Made by", (0, jsx_runtime_1.jsx)(__1.Username, { isVerified: props.author.isVerified, user: props.author, sx: { ml: 1 } })] }), props.contributors && props?.contributors.length ? ((0, jsx_runtime_1.jsxs)(theme_ui_1.Text, { "data-testid": "ArticleCallToAction: contributors", variant: "quiet", sx: { display: 'block', mt: 2, textAlign: 'center', fontSize: 2 }, children: ["With contributions from:", ' ', props.contributors.map((contributor, key) => ((0, jsx_runtime_1.jsx)(__1.Username, { user: contributor, isVerified: contributor.isVerified, sx: {
                                mr: 1,
                            } }, key)))] })) : null, (0, jsx_runtime_1.jsx)(theme_ui_1.Heading, { sx: { my: 4 }, children: "Like what you see? \uD83D\uDC47" }), (0, jsx_runtime_1.jsx)(theme_ui_1.Flex, { sx: {
                        gap: 2,
                        [`@media screen and (max-width: ${theme.breakpoints[0]})`]: {
                            flexDirection: 'column',
                        },
                    }, children: props.children })] }) }));
};
exports.ArticleCallToAction = ArticleCallToAction;
