"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommentList = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const theme_ui_1 = require("theme-ui");
const __1 = require("../");
const MAX_COMMENTS = 5;
const CommentList = ({ articleTitle, comments, handleEditRequest, handleDelete, highlightedCommentId, handleEdit, trackEvent, }) => {
    const [moreComments, setMoreComments] = (0, react_1.useState)(1);
    const shownComments = moreComments * MAX_COMMENTS;
    const scrollIntoRelevantComment = (commentId) => {
        setTimeout(() => {
            // the delay is needed, otherwise the scroll is not happening in Firefox
            document
                .getElementById(`comment:${commentId}`)
                ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 0);
    };
    (0, react_1.useEffect)(() => {
        if (!highlightedCommentId)
            return;
        const i = comments.findIndex((comment) => highlightedCommentId.includes(comment._id));
        if (i >= 0) {
            setMoreComments(Math.floor(i / MAX_COMMENTS) + 1);
            scrollIntoRelevantComment(highlightedCommentId);
        }
    }, [highlightedCommentId]);
    return ((0, jsx_runtime_1.jsxs)(theme_ui_1.Box, { mb: 4, sx: {
            width: '100%',
            display: 'block',
        }, children: [comments &&
                comments.slice(0, shownComments).map((comment) => ((0, jsx_runtime_1.jsx)(theme_ui_1.Box, { sx: {
                        marginBottom: 4,
                        border: `${highlightedCommentId === comment._id
                            ? '2px dashed black'
                            : 'none'}`,
                        borderRadius: 1,
                    }, children: (0, jsx_runtime_1.jsx)(__1.CommentItem, { ...comment, isUserVerified: !!comment.isUserVerified, isEditable: !!comment.isEditable, handleEditRequest: handleEditRequest, handleDelete: handleDelete, handleEdit: handleEdit }) }, comment._id))), comments && comments.length > shownComments && ((0, jsx_runtime_1.jsx)(theme_ui_1.Flex, { children: (0, jsx_runtime_1.jsx)(__1.Button, { sx: {
                        margin: '0 auto',
                    }, variant: "outline", onClick: () => {
                        trackEvent &&
                            trackEvent({
                                category: 'Comments',
                                action: 'Show more',
                                label: articleTitle,
                            });
                        return setMoreComments(moreComments + 1);
                    }, children: "show more comments" }) }))] }));
};
exports.CommentList = CommentList;
