import logo from '../../assets/images/balcao-logo-official.svg';
import badge from '../../assets/images/themes/precious-plastic/avatar_member_sm.svg';
import { styles } from './styles';
export const Theme = {
    id: 'precious-plastic',
    siteName: 'Balcão do Bairro',
    logo,
    badge,
    avatar: '',
    howtoHeading: `Learn & share how to solve your problems`,
    styles,
    academyResource: 'https://onearmy.github.io/academy/',
    externalLinks: [
        {
            url: 'https://bazar.preciousplastic.com/',
            label: 'Bazar',
        },
        {
            url: 'https://preciousplastic.com/',
            label: 'Global Site',
        },
    ],
};
