"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CharacterCount = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_ui_1 = require("theme-ui");
const CharacterCount = ({ currentSize, minSize, maxSize, }) => {
    const percentageOfMax = currentSize / maxSize;
    const characterCountThresholds = [
        { value: 1.0, color: 'red', font_weight: 'bold' },
        { value: 0.95, color: 'subscribed', font_weight: 'bold' },
        { value: 0.9, color: 'green', font_weight: 'bold' },
    ];
    let color = characterCountThresholds.find((threshold) => threshold.value <= percentageOfMax)?.color ?? 'green';
    let fontWeight = characterCountThresholds.find((threshold) => threshold.value <= percentageOfMax)?.font_weight ?? 'normal';
    if (currentSize < minSize) {
        color = characterCountThresholds[0].color;
        fontWeight = 'bold';
    }
    return ((0, jsx_runtime_1.jsxs)(theme_ui_1.Text, { "data-cy": "character-count", color: color, ml: "auto", sx: { fontSize: 1, margin: 1, fontWeight: fontWeight }, children: [currentSize, " / ", maxSize] }));
};
exports.CharacterCount = CharacterCount;
